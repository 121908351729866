#tableManagement-wrapper{
    position: relative;
    overflow: auto;
    width: 100%;
    max-height: calc(100vh - 4rem);

    #tableManagement{
        border-collapse: separate; /* Don't collapse */
        border-spacing: 0;

        /* Sticky Col */
        .sticky-col {
            position: -webkit-sticky;
            position: sticky;
            left: 0px;
            margin-top: -1px;
        }
        tr:hover td.sticky-col{background-color: inherit;}

        /* Sticky Row */
        .sticky-row {
            position: -webkit-sticky;
            position: sticky;
            top: 0px;
        }

        .white-border{
            th {
                border-right: 1px solid white;
                border-bottom: 1px solid white;

                &:last-child{border-right: 0}
            }

            &:nth-child(2) th{border-bottom: 0;}
        }

        tbody tr td,
        thead tr th{
            border-right: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
        }

        td.success,th.success{background-color: #c3e6cb!important;color: black!important;}
        td.warning,th.warning{background-color: #feecb8!important;color: black!important;}
        td.danger,th.danger{background-color: #fac3dd!important;color: black!important;}
        td.success:hover,th.success:hover{background-color: #b1dfbb!important;}
        td.warning:hover,th.warning:hover{background-color:#fee59f!important;}
        td.danger:hover,th.danger:hover{background-color: #f8abd0!important;}

        td.projet-vide{background-color: #c7c4dc;color: black}

        tr.hover-line:hover *{color: inherit;background-color: inherit;}
    }
}