$rose:#E3007E;
$jaune:#F9B800;
$bleu:#2F2C7C;
$rouge:#d90429;

.border-rose:focus,.border-rose:focus-visible,.border-rose:hover{
    border-color: $rose;
    outline-color: $rose;
}

.text-rouge{
    color: $rouge;
}
.supprimer:hover{
    color: $rouge;
}

.editer:hover{
    color: $rouge;
}