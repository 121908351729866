
.main-fader{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 99999999;
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      height: 300px;
      display: block;
      margin: 0 auto;
      path {
        animation-duration: 1s;
        animation-name: pulse;
        animation-iteration-count: infinite;
        color: #418CB6;

        &.path-7 {
          animation-delay: -1s
        }
        &.path-6 {
          animation-delay: -.875s
        }
        &.path-5 {
          animation-delay: -.75s
        }
        &.path-4 {
          animation-delay: -.625s
        }
        &.path-3 {
          animation-delay: -.5s
        }
        &.path-2 {
          animation-delay: -.375s
        }
        &.path-1 {
          animation-delay: -.25s
        }
        &.path-0 {
          animation-delay: -.125s
        }
      }
    }
  }
}


@keyframes pulse {
  0%     { opacity: .1; }
  30%    { opacity: .8; }
  100%   { opacity: .1; }
}

// NumberLoader

.Numberloader {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    border-radius: 50%;
  }
  
  .Numberloader {
    border: 3px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    width: 20px;
    height: 20px;
    animation: spin89345 1s linear infinite;
  }
  
  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  