@import 'base/variables';
@import 'base/reset';
@import 'base/typography';


body{
    background-color: rgb(249 250 251);
    font-family: Montserrat, sans-serif;
}

@import 'base/loading';

/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Components */
@import 'components/tableManagement';
/* Pages */