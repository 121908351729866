@font-face {
    font-family: Montserrat;
    src: url(../font/Montserrat.ttf);
}

@font-face {
    font-family: Montserrat-italic;
    src: url(../font/Montserrat-Italic.ttf);
}

.montserrat{
    font-family: Montserrat;
}

.montserrat-it{
    font-family: Monstserrat-italic;
}